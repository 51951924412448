@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input-phone-number input:focus{
  outline: none !important;
  border:none !important;
  box-shadow: none !important;
}

.rc-pagination{
  width: "fit-content";
  .rc-pagination-item {
    padding: 0.5rem 0.75rem;
    border-right: 1px solid #ddd;
  }
  .rc-pagination-prev {
    padding: 0.5rem 0.75rem;
    border-right: 1px solid #ddd;
  }
  .rc-pagination-next {
    padding: 0.5rem 0.75rem;
  }
  .rc-pagination-jump-next {
    padding: 0.5rem 0.75rem;
    border-right: 1px solid #ddd;
  }
  .rc-pagination-jump-prev {
    padding: 0.5rem 0.75rem;
    border-right: 1px solid #ddd;
  }
  .rc-pagination-item-active {
    background-color: #ececec;
  }
}